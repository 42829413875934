import React, {useState} from "react"
import styled from "styled-components"
import { Link as LinkS } from "react-scroll"
import { RiArrowLeftSFill as ArrowLeft } from "react-icons/ri"
import { RiArrowRightSFill as ArrowRight } from "react-icons/ri"

const SidebarWrapper = styled.div`
  padding: 10px;
  margin: 15px;
  padding-right: 0px;
  margin-top: 120px;
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
`

const ShrunkWrapper = styled.div`
  padding: 5px;
  margin: -10px;
  padding-right: 0px;
  margin-top: 60px;
  border-radius: 80px;
  background: #0B0040;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  align-items: center;
`
const SidebarButtons = styled(LinkS)`
  cursor: pointer;
  color: white;
  font-size: 16px;
  &:hover {
    text-decoration: none;
    color: #ec0f8c;
    font-weight: bold;
  }
  &.active {
    color: #ec0f8c;
    font-weight: bold;
  }
`

const SidebarButtonWrappers = styled.div`
  margin: 5px;
`

const SidebarContainer = styled.div`
  display: ${({isOpen}) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  width: 200px;
  z-index: 301;
  top: 8%;
  left: 0;
  padding-top: 20px;
`

const ShrunkSidebarContainer = styled.div `
  display: ${({isOpen}) => (isOpen ? 'none' : 'flex')};
  position: fixed;
  width: 200px;
  z-index: 301;
  top: 12%;
  left: 0;
  padding-top: 70px;
`

const Left = styled(ArrowLeft) `
  color: white;
  height: 100%; 
  width: 100%; 
`

const Right = styled(ArrowRight) `
  color: white;
  height: 100%; 
  width: 100%; 
`

const ArrowButton = styled.button`
  width: 30px;
  height: 30px;
  background: none;
  border: none;
`


export const SidebarObject = props => {
  const { sidebarData, toggle, isOpen } = props
  console.log(isOpen)
  if (sidebarData == undefined) {
    return null
  } else {
    return (
      <>
      <ShrunkSidebarContainer isOpen={isOpen}>
        <ShrunkWrapper>
          <ArrowButton onClick={toggle}><Right/></ArrowButton>
        </ShrunkWrapper>
      </ShrunkSidebarContainer>
      <SidebarContainer isOpen={isOpen}>
        <SidebarWrapper>
          <div>
            {sidebarData.map((data, index) => (
              <SidebarButtonWrappers>
                <SidebarButtons
                  to={data.to}
                  smooth={true}
                  offset={-70}
                  spy={true}
                >
                  {data.title}
                </SidebarButtons>
              </SidebarButtonWrappers>
            ))}
          </div>
        <ArrowButton onClick={toggle}><Left/></ArrowButton>
        </SidebarWrapper>
      </SidebarContainer>
      </>
    )
  }
}
