import React, { useState } from "react"
import NoSidebarLayout from "../layout/NoSidebarLayout"
import styled from "styled-components"
import { RealButton } from "../components/ButtonElement"
import {contactBanner, WeChat} from "../images/"
import SEO from "../components/seo"

const QRCodeContainer = styled.img `
  width: 70%;
  height: auto;
  justify-self: center;
  align-self: center;
  margin: 0 auto;
`

const QRCodeDescription = styled.div `
  text-align: center;
  margin: 20px;
`

const sidebar = [
  {
    to: "aboutUs",
    title: "About Us",
  },
  {
    to: "ourStory",
    title: "Our Story",
  },
  {
    to: "ourTeam",
    title: "Our Team",
  },
  {
    to: "ourAchievements",
    title: "Our Achievements",
  },
]

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const FormSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 10px 30px 10px;
`

export default function Contact() {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    number: "",
    department: "Guardianship",
    message: "",
    success: false,
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => {
        setFormState({
          name: "",
          email: "",
          number: "",
          department: "Guardianship",
          message: "",
          success: true,
        })

      })
      .catch(error => alert(error))
    e.preventDefault()
  }

  return (
        <NoSidebarLayout sidebar={sidebar} pageTitle="聯繫我們
        " background={contactBanner} pageDescription="給我們留言或通過社交媒體與我們聯繫">
          <SEO title="Contact | Guardianship | Schools | Homestay | Agents" description="Study Links works alongside the best schools & other educational institutions in the UK. Get in touch with us for more information."/>
          <QRCodeContainer src={WeChat}/>
          <QRCodeDescription>（微信二維碼）</QRCodeDescription>
        </NoSidebarLayout>
  )
}
