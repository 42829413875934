import * as styledC from "./NavbarElements";
import React, { useState, useEffect } from 'react'
import {AboutItem, GuardianshipItem, HomestayItem, BlogItem, WorkItem, ContactItem, MobileIconItem, NavLogoItem, EducationItem, SupportItem} from "./NavBarItems"
import {Icons} from '../Footer/Social'
import styled from 'styled-components'

const CornerIconContainer = styled.div `
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: 1250px) {
    display: none;
  }
`

const Navbar = ({toggle}) => {
  const [scrollNav, setScrollNav] = useState(false)
  useEffect(() => {
    const changeNav = () => {
      if (window.scrollY >= 0.1) {
        setScrollNav(true);
      } else {
        setScrollNav(false);
      }
    }
    window.addEventListener('scroll', changeNav);
  }, []);

  return (
    <>
      <styledC.Nav scrollNav = {scrollNav}>
        <NavLogoItem />
        <styledC.NavbarContainer>
          <MobileIconItem toggle={toggle}/>
          <styledC.NavMenu>
            <AboutItem />
            <GuardianshipItem />
            <HomestayItem />
            <EducationItem/>
            <SupportItem/>
            <ContactItem />
          </styledC.NavMenu>
            <CornerIconContainer>
              <Icons />
            </CornerIconContainer>
        </styledC.NavbarContainer>
      </styledC.Nav>
    </>
  )
}

export default Navbar